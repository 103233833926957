import { FunctionComponent, useEffect, useRef, useState } from 'react';

import './Ripple.light.scss';

interface Props {
  /**
   * Whether the ripple should stay centered within the enclosing component
   * or whether it should be centered around the click point
   */
  fixed?: boolean;

  /**
   * Duration of the ripple effect
   */
  duration?: number;
}

/**
 * Add a water ripple effect. Example usage
 *
 * ```
 *  <Button>
 *    <Ripple />
 *  </Button>
 * ```
 *
 * @param props
 * @returns
 */
export function Ripple(props: Props) {
  const rippleContainerRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ left: number | undefined; top: number | undefined }>({
    left: undefined,
    top: undefined
  });

  useEffect(() => {
    const ripple = rippleContainerRef.current;
    const rippleTrigger = ripple.parentElement;
    const rippleEffect = ripple.firstElementChild as HTMLSpanElement;
    const showRipple = (event: PointerEvent) => {
      const rippleTriggerBoundingBox = rippleTrigger.getBoundingClientRect();
      const rippleTriggerLargerDimension = Math.max(rippleTriggerBoundingBox.width, rippleTriggerBoundingBox.height);
      if (!props.fixed) {
        setPosition({
          left: event.clientX - rippleTriggerBoundingBox.left - rippleTriggerLargerDimension / 2,
          top: event.clientY - rippleTriggerBoundingBox.top - rippleTriggerLargerDimension / 2
        });
      }
      rippleTrigger.removeChild(ripple);
      rippleEffect.style.animationIterationCount = '1';
      rippleTrigger.prepend(ripple);

      rippleEffect.style.width = `${rippleTriggerLargerDimension}px`;
      rippleEffect.style.height = `${rippleTriggerLargerDimension}px`;
    };
    rippleTrigger.addEventListener('pointerdown', showRipple, false);
    return () => rippleTrigger.removeEventListener('pointerup', showRipple, false);
  }, [props.fixed]);

  return (
    <div
      ref={rippleContainerRef}
      className={`ripple${props.fixed ? ' fixed' : ''}`}>
      <span
        className='ripple__effect'
        style={{
          left: position.left,
          top: position.top,
          animationDuration: `${props.duration}ms`
        }}
      />
    </div>
  );
}

(Ripple as FunctionComponent<Props>).defaultProps = {
  fixed: false,
  duration: 2000
};
