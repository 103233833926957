import { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import { Button } from '@common/button';
import { Ripple } from '@common/effects/ripple';
import { PortalRenderer } from '@common/overlay/portal-renderer';

import './Notification.light.scss';

interface Props {
  show?: boolean;
  onHide?: () => void;
  children: ReactNode;
}

export function Notification(props: Props) {
  const animationDuration = 500;
  const visibilityDuration = 30_000;
  const [isVisible, setIsVisible] = useState(props.show);
  const [scheduler, setScheduler] = useState(-Infinity);
  const hideNotification = () => {
    if (props.onHide) {
      window.setTimeout(props.onHide, animationDuration);
    }
    setIsVisible(false);
  };

  useEffect(() => {
    if (props.show) {
      setIsVisible(true);
      setScheduler(window.setTimeout(hideNotification, visibilityDuration));
    } else {
      window.clearTimeout(scheduler);
      hideNotification();
    }
    return () => {
      window.clearTimeout(scheduler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    const onEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        hideNotification();
      }
    };
    window.addEventListener('keyup', onEscapeKeyPress, false);
    return () => {
      window.removeEventListener('keyup', onEscapeKeyPress, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`notification-container ${isVisible ? 'visible' : 'hidden'}`}
      onPointerUp={hideNotification}>
      <div className='notification'>
        {props.children}
        <Button
          className='notification__close'
          variant='basic'
          color='primary'>
          <Ripple />
          Close
        </Button>
      </div>
    </div>
  );
}

(Notification as FunctionComponent<Props>).defaultProps = {
  show: true
};
Notification.open = (content: ReactNode) => {
  return new Promise<void>(resolve => {
    const portalRenderer = new PortalRenderer();
    portalRenderer.mount(
      <Notification
        onHide={() => {
          resolve();
          portalRenderer.unmount();
        }}>
        {content}
      </Notification>
    );
  });
};
