import { Component, ComponentPropsWithoutRef, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { createRoot } from 'react-dom/client';

import './PortalRenderer.light.scss';

interface Props extends ComponentPropsWithoutRef<any> {
  containerClassName?: string;
  portal?: HTMLElement;
}

interface State {}

/**
 * Render props.children into a portal
 */
export class PortalRenderer extends Component<Props, State> {
  static defaultProps = {
    portal: document.body
  } as Props;

  readonly container = document.createElement('div');

  private readonly _root = createRoot(this.container);

  constructor(props: Props = PortalRenderer.defaultProps) {
    super(props);

    if (!props.portal) {
      props.portal = document.body;
    }

    this.container.className = 'portal-renderer';
    if (this.props.containerClassName) {
      this.container.className += ' ' + this.props.containerClassName;
    }
    props.portal.appendChild(this.container);

    this.unmount = this.unmount.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.containerClassName !== prevProps.containerClassName) {
      this.container.className = 'portal-renderer';
      if (this.props.containerClassName) {
        this.container.className += ' ' + this.props.containerClassName;
      }
    }
  }

  render() {
    return createPortal(this.props.children, this.container);
  }

  componentWillUnmount() {
    if (this.props.portal.contains(this.container)) {
      this.props.portal.removeChild(this.container);
    }
  }

  mount(component: ReactElement<HTMLElement>) {
    this._root.render(component);
  }

  unmount() {
    if (this.props.portal.contains(this.container)) {
      this.props.portal.removeChild(this.container);
    }
    if (this.props.portal !== document.body) {
      this.props.portal.parentElement.removeChild(this.props.portal);
    }
    this._root.unmount();
  }
}
