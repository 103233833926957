import { ComponentPropsWithoutRef } from 'react';

import './DialogContent.light.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {}

export function DialogContent({ className, children, ...rest }: Props) {
  return (
    <div
      className={`dialog-content${className ? ' ' + className : ''}`}
      {...rest}>
      {children}
    </div>
  );
}
