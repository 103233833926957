import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, IconButton } from '@common/button';
import { CallHistory, generateCallHistoryPdf } from '@common/call-history-pdf-generator';
import { Ripple } from '@common/effects/ripple';
import { FilePicker, FilePickerService, FileUpload, FileUploadStatus } from '@common/file-picker';
import { Icon } from '@common/icon';
import { Box } from '@common/box';
import { ConfirmationDialog } from '@common/overlay/dialog';
import { Notification } from '@common/overlay/notification';
import { ProgressIndicator } from '@common/overlay/progress-indicator';
import { FormControl, RadioButton, RadioButtonGroup } from '@common/form';
import { ScrollContainer } from '@common/scroll-container';
import { SortOrder } from '@common/SortOrder';

import './App.scss';

export function App() {
  const [fileUploads, setFileUploads] = useState<FileUpload<CallHistory>[]>([]);
  const [generateButtonDisabled, setGenerateButtonDisabled] = useState(true);

  const dateOrderFormControl = useMemo(() => new FormControl(SortOrder.ASCENDING), []);
  const showPageCounterFormControl = useMemo(() => new FormControl(true), []);

  const hasUploadError = (uploads: FileUpload<unknown>[]) => {
    return uploads.some(upload => upload.status === FileUploadStatus.FAILURE);
  };

  const onSelectFiles = useCallback(() => {
    ProgressIndicator.show();
    FilePickerService.getInstance()
      .open()
      .readFilesAsJson<CallHistory>()
      .subscribe({
        next: uploads => {
          setGenerateButtonDisabled(hasUploadError(uploads));
          setFileUploads(previousUploads => {
            const existingUploads = previousUploads.slice(0);
            for (const upload of uploads) {
              if (existingUploads.every(e => e.file.name !== upload.file.name || e.file.size !== upload.file.size)) {
                existingUploads.push(upload);
              }
            }
            return existingUploads;
          });
        },
        complete: ProgressIndicator.hide
      });
  }, []);

  const onRemoveUpload = useCallback(
    (uploadToRemove: FileUpload<CallHistory>) => {
      ConfirmationDialog.open(`Do you want to remove file ${uploadToRemove.file.name}`).then(() => {
        const updatedFileUploads = fileUploads.filter(file => file !== uploadToRemove);
        setFileUploads(updatedFileUploads);
        setGenerateButtonDisabled(hasUploadError(updatedFileUploads));
      });
    },
    [fileUploads, setFileUploads]
  );

  const onClearAllFiles = useCallback(() => {
    ConfirmationDialog.open('Do you want to clear all uploaded files?').then(() => {
      setFileUploads([]);
      setGenerateButtonDisabled(true);
    });
  }, []);

  const onGeneratePdf = useCallback(async () => {
    ProgressIndicator.show();
    try {
      const successfulUploads = fileUploads.filter(e => e.status === FileUploadStatus.SUCCESS);
      if (successfulUploads.length === fileUploads.length) {
        const participants = successfulUploads[0].content.participants;
        const combinedCallHistory: CallHistory = {
          participants,
          messages: []
        };

        for (const successfulUpload of successfulUploads) {
          combinedCallHistory.messages.push(
            ...successfulUpload.content.messages
              .filter(message => message.type === 'Call')
              .map(message => {
                if (message.content.includes('video chat ended')) {
                  return message;
                }

                /*
                If the participants had set nicknames for each other, and the nicknames
                contain any UTF-16 emojis, then the message content will show the nicknames
                instead of the actual names of the participants, but because jspdf doesn't support
                UTF-16 encoding yet, those emojis wouldn't be rendered at all in the final
                PDF, so as a sanitization step, we recompute the message content using the
                actual names of the participants.
                */
                if (message.content.includes('missed your video chat')) {
                  const callee = participants.find(participant => participant.name !== message.sender_name);
                  message.content = `${callee.name} missed your video chat.`;
                } else if (message.content.includes('missed your call')) {
                  const callee = participants.find(participant => participant.name !== message.sender_name);
                  message.content = `${callee.name} missed your call.`;
                } else if (message.content.includes('called you')) {
                  message.content = `${message.sender_name} called you.`;
                } else if (message.content.includes('You called')) {
                  const callee = participants.find(participant => participant.name !== message.sender_name);
                  message.content = `You called ${callee.name}`;
                } else if (message.content.includes('You missed a video chat with')) {
                  message.content = `You missed a video chat with ${message.sender_name}`;
                } else if (message.content.includes('You missed a call from')) {
                  message.content = `You missed a call from ${message.sender_name}.`;
                }

                return message;
              })
          );
        }

        combinedCallHistory.messages.sort(
          (a, b) => dateOrderFormControl.getValue() * (a.timestamp_ms - b.timestamp_ms)
        );

        await generateCallHistoryPdf(combinedCallHistory, {
          sortOrder: dateOrderFormControl.getValue(),
          showPageCounter: showPageCounterFormControl.getValue()
        });

        Notification.open('Call history PDF was generated successfully.');
      } else {
        throw new Error(`Unable to read files ${fileUploads.map(e => e.file.name).join(', ')}`);
      }
    } catch (error) {
      Notification.open(`Failed to generate call history PDF (Reason is "${error.message}").`);
    } finally {
      ProgressIndicator.hide();
    }
  }, [dateOrderFormControl, fileUploads, showPageCounterFormControl]);

  useEffect(() => {
    window.onhashchange = () => {
      const anchor = document.getElementById(location.hash.slice(1));
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth' });
      }
    };
  }, []);

  return (
    <>
      <header className=''>
        <h2>Free online tool to export Facebook Messenger call history into a PDF file</h2>
        <a href='#how-to-download-messenger-message-history-as-json'>
          How to download your Messenger message history as JSON
        </a>
      </header>
      <Box className='messenger-call-history-pdf-generator'>
        <IconButton
          variant='raised'
          color='primary'
          onClick={onSelectFiles}>
          <Ripple />
          <Icon icon='file_upload' />
          Add message JSON files
        </IconButton>
        {fileUploads.length > 0 && (
          <>
            <ScrollContainer heading='Uploaded files'>
              <ul className='file-list'>
                {fileUploads.map(upload => (
                  <li
                    key={upload.file.name + upload.file.size}
                    className={`file-list__item${upload.status === FileUploadStatus.SUCCESS ? '' : ' failed'}`}>
                    <IconButton
                      className='file-list__remove-item'
                      variant='basic'
                      color='warning'
                      onClick={() => onRemoveUpload(upload)}>
                      <Ripple fixed />
                      <Icon icon='close' />
                    </IconButton>
                    <div className='file-list__upload-details'>
                      <span className='file-list__file-name'>{upload.file.name}</span>
                      <span className='file-list__file-size'>
                        <strong>File size:</strong> {formatFileSize(upload.file)}
                      </span>
                      <span className='file-list__upload-status'>
                        <strong>Error:</strong> {upload.errorMessage}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </ScrollContainer>

            <ScrollContainer heading='Exporter options'>
              <RadioButtonGroup
                label='Date order'
                formControl={dateOrderFormControl}>
                <RadioButton
                  label='Ascending (Oldest to latest)'
                  value={SortOrder.ASCENDING}
                />
                <RadioButton
                  label='Descending (Latest to oldest)'
                  value={SortOrder.DESCENDING}
                />
              </RadioButtonGroup>
              <RadioButtonGroup
                label='Show page numbers'
                formControl={showPageCounterFormControl}>
                <RadioButton
                  label='Yes'
                  value={true}
                />
                <RadioButton
                  label='No'
                  value={false}
                />
              </RadioButtonGroup>
            </ScrollContainer>

            <Button
              className='clear-all-files'
              variant='stroked'
              color='accent'
              disabled={fileUploads.length === 0}
              onClick={onClearAllFiles}>
              <Ripple />
              Clear all files
            </Button>

            <Button
              className='generate-pdf'
              variant='raised'
              color='primary'
              onClick={onGeneratePdf}
              disabled={generateButtonDisabled}>
              <Ripple />
              Generate PDF
            </Button>
          </>
        )}
      </Box>

      <Box
        className='how-to-download-container'
        id='how-to-download-messenger-message-history-as-json'>
        <header>
          <h2>How to download Messenger message history as JSON</h2>
        </header>
        <ul>
          <li className='how-to-download-steps'>
            On desktop
            <ol>
              <li className='how-to-download-step'>
                Navigate to{' '}
                <a
                  href='https://facebook.com'
                  target='_blank'
                  rel='noreferrer'>
                  https://facebook.com
                </a>
              </li>
              <li className='how-to-download-step'>
                Go into <strong>Settings & privacy</strong> 🡒 <strong>Settings</strong>
              </li>
              <li className='how-to-download-step'>
                On the left panel, select <strong>Your Facebook information</strong>
              </li>
              <li className='how-to-download-step'>
                Click on <strong>Download your information</strong>
              </li>
              <li className='how-to-download-step'>
                For <strong>Format</strong>, select <strong>JSON</strong>
              </li>
              <li className='how-to-download-step'>
                For <strong>Media quality</strong>, select <strong>Low</strong>
              </li>
              <li className='how-to-download-step'>
                For <strong>Date range</strong>, select <strong>All time</strong>
              </li>
              <li className='how-to-download-step'>
                Click <strong>Deselect all</strong>, then tick the box <strong>Messages</strong>
              </li>
              <li className='how-to-download-step'>
                Scroll to the bottom and click <strong>Request a download</strong>
              </li>
            </ol>
          </li>
          <li className='how-to-download-steps'>
            On mobile app
            <ol>
              <li className='how-to-download-step'>
                Go into <strong>Settings & privacy</strong>
              </li>
              <li className='how-to-download-step'>
                Scroll down to the <strong>Your information</strong> section
              </li>
              <li className='how-to-download-step'>
                Click on <strong>Download your information</strong>
              </li>
              <li className='how-to-download-step'>
                For <strong>Your activity across Facebook</strong>, press <strong>Deselect all</strong>, then tick the
                box <strong>Messages</strong>
              </li>
              <li className='how-to-download-step'>
                For <strong>Date range</strong>, select <strong>All time</strong>
              </li>
              <li className='how-to-download-step'>
                For <strong>Format</strong>, select <strong>JSON</strong>
              </li>
              <li className='how-to-download-step'>
                For <strong>Media quality</strong>, select <strong>Low</strong>
              </li>
              <li className='how-to-download-step'>
                Finally, press <strong>CREATE FILE</strong>
              </li>
            </ol>
          </li>
        </ul>
      </Box>
      <FilePicker />
    </>
  );
}

function formatFileSize({ size: fileSize }: File) {
  const gb = fileSize / 1024 ** 3;
  if (gb >= 1) {
    return `${gb.toFixed(2)}GB`;
  }

  const mb = fileSize / 1024 ** 2;
  if (mb >= 1) {
    return `${mb.toFixed(2)}MB`;
  }

  const kb = fileSize / 1024;
  if (kb >= 1) {
    return `${kb.toFixed(2)}KB`;
  }

  return `${fileSize.toFixed(2)}B`;
}
