import { ComponentPropsWithoutRef } from 'react';

import './ScrollContainer.light.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {
  heading?: string;
}

export function ScrollContainer({ heading, className, children, ...rest }: Props) {
  return (
    <div
      className={`scroll-container${className ? ' ' + className : ''}`}
      data-heading={heading || ''}
      {...rest}>
      <div className='scroll-container__content'>{children}</div>
    </div>
  );
}
