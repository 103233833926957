import { useEffect, useState } from 'react';

import { FormControl } from '../models/FormControl';

import './FormField.light.scss';

interface Props<T> {
  label: string;
  className: string;
  formControl: FormControl<T>;
  children: React.ReactNode;
  hint?: string;
  htmlFor?: string;
}

export function FormField<T>(props: Props<T>) {
  const [isDisabled, setIsDisabled] = useState(props.formControl.isDisabled());
  const [isValid, setIsValid] = useState(props.formControl.isValid());

  useEffect(() => {
    const subscription1 = props.formControl.onDisableToggled().subscribe({
      next: setIsDisabled
    });

    const subscription2 = props.formControl.validityChanges().subscribe({
      next: setIsValid
    });
    return () => {
      subscription1.unsubscribe();
      subscription2.unsubscribe();
    };
  }, [props.formControl, setIsValid]);

  return (
    <div
      className={
        'form-field' +
        (props.className ? ' ' + props.className : '') +
        (isDisabled ? ' disabled' : '') +
        (!isValid && !props.formControl.isPristine() ? ' invalid' : '')
      }>
      {props.label && (
        <label
          className='form-field__label'
          htmlFor={props.htmlFor}>
          {props.label}
          &nbsp;
          <span className='form-field__label-hint'>{props.hint}</span>
        </label>
      )}
      <div className='form-field__body'>{props.children}</div>
    </div>
  );
}
