import { Button } from '@common/button';
import { Ripple } from '@common/effects/ripple';

import { Dialog } from './Dialog';

import './ConfirmationDialog.light.scss';

export class ConfirmationDialog {
  static open(message: string) {
    return new Promise<void>(resolve => {
      const dialogRef = Dialog.create(message)
        .addClassName('confirmation-dialog')
        .transparentBackdrop()
        .dismissible()
        .addButton(
          <Button
            variant='basic'
            color='primary'
            onClick={() => {
              dialogRef.close();
            }}>
            <Ripple />
            Cancel
          </Button>
        )
        .addButton(
          <Button
            variant='raised'
            color='primary'
            onClick={() => {
              dialogRef.close();
              resolve();
            }}>
            <Ripple />
            Confirm
          </Button>
        )
        .open();
    });
  }
}
