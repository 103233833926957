import { Subject } from 'rxjs';

/**
 * A base class for different form control types. It keeps track of this control's current value,
 * validity state, and other details. It also dispatches notifications when something has changed.
 */
export abstract class AbstractControl<T> {

  protected readonly _validityChangeNotifier = new Subject<boolean>();
  protected readonly _valueChangeNotifier = new Subject<T>();
  protected readonly _resetNotifier = new Subject<void>();
  protected readonly _disableStatusNotifier = new Subject<boolean>();

  protected _isValid = true;
  protected _isPristine = true;
  protected _isDisabled = false;

  abstract getValue(): T;
  abstract setValue(newValue: T): void;
  abstract reset(): void;

  isPristine() {
    return this._isPristine;
  }

  markDirty() {
    this._isPristine = false;
  }

  markPristine() {
    this._isPristine = true;
  }

  isValid() {
    return this._isValid;
  }

  isInvalid() {
    return !this._isValid;
  }

  setValidity(isValid: boolean) {
    this._isValid = isValid;
    this._validityChangeNotifier.next(isValid);
  }

  validityChanges() {
    return this._validityChangeNotifier.asObservable();
  }

  valueChanges() {
    return this._valueChangeNotifier.asObservable();
  }

  isDisabled() {
    return this._isDisabled;
  }

  disable() {
    this._isDisabled = true;
    this._disableStatusNotifier.next(true);
  }

  enable() {
    this._isDisabled = false;
    this._disableStatusNotifier.next(false);
  }

  onReset() {
    return this._resetNotifier.asObservable();
  }

  onDisableToggled() {
    return this._disableStatusNotifier.asObservable();
  }

}
