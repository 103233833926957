import { ReactNode } from 'react';

import './DialogActionGroup.light.scss';

interface Props {
  children: ReactNode | ReactNode[];
}

export function DialogActionGroup(props: Props) {
  return (
    <div className='dialog-action-group'>
      {
        props.children
      }
    </div>
  );
}
