import { ComponentPropsWithoutRef } from 'react';

import { Button } from '../button';

import './IconButton.light.scss';

export function IconButton(props: ComponentPropsWithoutRef<typeof Button>) {
  return (
    <Button
      {...props}
      className={`icon-button${props.className ? ' ' + props.className : ''}`}>
      {props.children}
    </Button>
  );
}
