import { AbstractControl } from './AbstractControl';

/**
 * This class represents the model for a form field control, it abstracts away the details of keeping track
 * of the form field's value and other details such validity, etc. Example usage could look like this
 *
 * ```
 *  export function AddressForm() {
 *    const streetFormControlRef = useRef(new FormControl('', [Validators.checkNotEmpty()]));
 *    .
 *    .
 *    .
 *    return (
 *      <ShortTextFormField formControl={streetFormControlRef} />
 *    );
 *  }
 * ```
 *
 * `useRef` is necessary when constructing FormControl objects inside a function component because
 * React will reuse the same FormControl instance every render instead of accidentally using a new
 * one.
 *
 * Generic type T is the type of the value that this control is holding
 */
export class FormControl<T> extends AbstractControl<T> {
  private _errors: Record<string, string> = null;

  private _currentValue: T;

  /**
   * Create a new FormControl instance by giving it an initial value and optionally a list of validation functions
   *
   * @param _initialValue The initial form control's value
   */
  constructor(private readonly _initialValue: T) {
    super();

    this._updateValue(_initialValue, false);
  }

  /**
   * Update this control's value to the provided value
   *
   * @param newValue The new value for this form control
   */
  setValue(newValue: T) {
    this._updateValue(newValue);
  }

  /**
   * Update the stored value in this control to the provided value
   *
   * @param newValue This control's new value
   * @param notifyValueChange Whether or not this new value change event should be broadcast to all listeners
   */
  private _updateValue(newValue: T, notifyValueChange = true) {
    this._currentValue = newValue;
    this._isValid = this.validate();
    if (!this._isPristine) {
      this._validityChangeNotifier.next(this._isValid);
    } else if (this._isValid) {
      this._isPristine = false;
      this._validityChangeNotifier.next(this._isValid);
    }
    if (notifyValueChange) {
      this._valueChangeNotifier.next(this._currentValue);
    }
  }

  /**
   * Run all of the validation functions that were added to this form control
   *
   * @returns whether the currently stored value in this control passes every validation check
   */
  validate(): boolean {
    return true;
  }

  getValue() {
    return this._currentValue;
  }

  get errors() {
    return this._errors;
  }

  /**
   * Reset this form control to the same state as when it was first created
   */
  reset() {
    this._isPristine = true;
    this._updateValue(this._initialValue);
    this._validityChangeNotifier.next(this._isValid);
    this._valueChangeNotifier.next(this._currentValue);
    this._resetNotifier.next();
    this._disableStatusNotifier.next(this._isDisabled);
  }
}
