/* eslint-disable @typescript-eslint/indent */

import { FunctionComponent } from 'react';

import './Icon.light.scss';

/**
 * The icon types can be found on https://fonts.google.com/icons
 */
type IconType =
  | 'close'
  | 'file_upload'
  | 'east'
  | 'radio_button_checked'
  | 'radio_button_unchecked'
  | 'check_box_outline_blank'
  | 'check_box';

interface Props {
  icon: IconType;
  className?: string;
  style?: 'round' | 'outlined';
}

export function Icon(props: Props) {
  return (
    <span
      className={`icon material-icons${props.className ? ' ' + props.className : ''}`}
      style={{
        fontFamily: `Material Icons ${props.style === 'round' ? 'Round' : 'Outlined'}`
      }}>
      {props.icon}
    </span>
  );
}

(Icon as FunctionComponent<Props>).defaultProps = {
  style: 'round'
};
