import { ComponentPropsWithoutRef } from 'react';

import './Box.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {}

export function Box({ className, children, ...rest }: Props) {
  return (
    <div
      className={`box${className ? ' ' + className : ''}`}
      {...rest}>
      {children}
    </div>
  );
}
