import { Fade } from '../../effects/fade';

import './Backdrop.light.scss';

interface Props {
  visible: boolean;
  transparent?: boolean;
  onClick?: () => void;
}

export function Backdrop(props: Props) {
  return (
    <Fade in={props.visible}>
      <div
        className={`backdrop${props.transparent ? ' transparent' : ''}`}
        onClick={props.onClick}
      />
    </Fade>
  );
}
