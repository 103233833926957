import { Fade } from '../../effects/fade';
import { Backdrop } from '../backdrop';
import { PortalRenderer } from '../portal-renderer';

import './ProgressIndicator.dark.scss';
import './ProgressIndicator.light.scss';

/**
 * The progress indicator, to show it, call `ProgressIndicator.show()`,
 * then `ProgressIndicator.hide()` to hide it
 */
export function ProgressIndicator() {
  return (
    <Fade in>
      <div className='progress-indicator'>
        <Backdrop visible />
        <div className='progress-indicator__dot-container'>
          <span
            className='progress-indicator__dot'
            data-test='progress-indicator-dot'
          />
          <span
            className='progress-indicator__dot'
            data-test='progress-indicator-dot'
          />
          <span
            className='progress-indicator__dot'
            data-test='progress-indicator-dot'
          />
          <span
            className='progress-indicator__dot'
            data-test='progress-indicator-dot'
          />
          <span
            className='progress-indicator__dot'
            data-test='progress-indicator-dot'
          />
          <span
            className='progress-indicator__dot'
            data-test='progress-indicator-dot'
          />
          <span
            className='progress-indicator__dot'
            data-test='progress-indicator-dot'
          />
          <span
            className='progress-indicator__dot'
            data-test='progress-indicator-dot'
          />
          <span
            className='progress-indicator__dot'
            data-test='progress-indicator-dot'
          />
        </div>
      </div>
    </Fade>
  );
}

let portalRenderer: PortalRenderer;

ProgressIndicator.show = () => {
  if (portalRenderer) {
    portalRenderer.unmount();
  }
  portalRenderer = new PortalRenderer();
  portalRenderer.mount(<ProgressIndicator />);
};

ProgressIndicator.hide = () => {
  if (portalRenderer) {
    portalRenderer.unmount();
    portalRenderer = null;
  }
};
