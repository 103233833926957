export interface FileUpload<T> {
  readonly file: File;
  content: T | null;
  errorMessage: string;
  status: FileUploadStatus;
}

export const enum FileUploadStatus {
  SUCCESS,
  FAILURE
}
