import { Buffer } from 'buffer';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { SortOrder } from '@common/SortOrder';

import { CallHistory } from './CallHistory';
import { GeneratorOptions } from './GeneratorOptions';

// https://rawgit.com/MrRio/jsPDF/master/fontconverter/fontconverter.html
// https://fontlibrary.org/en/font/symbola

const font = 'LucidaGrande';
const dateFormatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit' });
const timeFormatter = new Intl.DateTimeFormat('en-US', {
  timeStyle: 'short'
});

export async function generateCallHistoryPdf(callHistory: CallHistory, options: GeneratorOptions) {
  const pdfDoc = await initializePdfDoc();
  const participants = callHistory.participants.map(p => p.name);
  const messages = callHistory.messages.sort((a, b) => (a.timestamp_ms - b.timestamp_ms) * options.sortOrder);

  pdfDoc.setFont(`${font}-Normal`);
  pdfDoc.setFontSize(15);
  pdfDoc.setTextColor(40);
  pdfDoc.text(`Messenger call history: ${Buffer.from(participants.join(' and '), 'latin1')}`, 30, 30);

  pdfDoc.setFontSize(10);
  pdfDoc.text(
    [
      `Total: ${pluralize(messages.length, 'record')}`,
      `From date: ${dateFormatter.format(
        messages[options.sortOrder === SortOrder.ASCENDING ? 0 : messages.length - 1].timestamp_ms
      )}`,
      'Call history extracted and printed using online tool'
    ],
    30,
    44
  );

  pdfDoc.setTextColor('blue');
  pdfDoc.textWithLink(
    'https://messengercallhistory.com',
    pdfDoc.getTextWidth('Call history extracted and printed using online tool') + 33,
    67,
    {}
  );

  autoTable(pdfDoc, {
    head: [['Date', 'Time', 'Caller', 'Log', 'Duration']],
    body: messages.map(message => [
      dateFormatter.format(message.timestamp_ms),
      timeFormatter.format(message.timestamp_ms),
      Buffer.from(message.sender_name, 'latin1').toString(),
      Buffer.from(message.content, 'latin1').toString(),
      formatDuration(message.call_duration)
    ]),
    startY: 77,
    headStyles: {
      font: `${font}-Bold`
    },
    styles: {
      font: `${font}-Normal`
    }
  });

  if (options.showPageCounter) {
    addPageCounterFooter(pdfDoc);
  }

  pdfDoc.save('messenger_call_history.pdf');
}

async function initializePdfDoc() {
  const pdfDoc = new jsPDF('l', 'pt', 'a4');
  const lucidaGrandeFont = await (await fetch(`${process.env.PUBLIC_URL}/fonts/LucidaGrande.json`)).json();

  pdfDoc.addFileToVFS('LucidaGrande-Normal.ttf', lucidaGrandeFont.normal);
  pdfDoc.addFont('LucidaGrande-Normal.ttf', 'LucidaGrande-Normal', 'normal');
  pdfDoc.addFileToVFS('LucidaGrande-Bold.ttf', lucidaGrandeFont.bold);
  pdfDoc.addFont('LucidaGrande-Bold.ttf', 'LucidaGrande-Bold', 'bold');

  return pdfDoc;
}

function pluralize(value: number, label: string, showZeroValue = false) {
  return !showZeroValue && value === 0 ? '' : `${value} ${label}${value !== 1 ? 's' : ''}`;
}

function formatDuration(duration: number) {
  const hours = Math.trunc(duration / 3600);
  const minutes = Math.trunc((duration - hours * 3600) / 60);

  return [
    pluralize(hours, 'hour'),
    pluralize(minutes, 'minute'),
    pluralize(duration - hours * 3600 - minutes * 60, 'second', true)
  ]
    .join(' ')
    .trim();
}

function addPageCounterFooter(pdfDoc: jsPDF) {
  const pageFooterX = pdfDoc.internal.pageSize.width - 95;
  const pageFooterY = pdfDoc.internal.pageSize.height - 20;
  const numberOfPages = pdfDoc.getNumberOfPages();

  pdfDoc.setTextColor('black');
  pdfDoc.setFontSize(9);

  for (let i = 0; i < numberOfPages; i++) {
    const currentPageNumber = i + 1;

    pdfDoc.setPage(currentPageNumber);
    pdfDoc.text(`Page ${currentPageNumber} of ${numberOfPages}`, pageFooterX, pageFooterY);
  }
}
