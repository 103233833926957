import { useCallback } from 'react';

import { FilePickerService } from './FilePickerService';

import './FilePicker.light.scss';

export function FilePicker() {
  const onFilePicked = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    FilePickerService.getInstance().selectFiles(event.target.files);
  }, []);

  return (
    <form className='file-picker'>
      <input
        className='file-picker__input'
        accept='application/json, .json'
        type='file'
        name='filePicker'
        multiple
        onChange={onFilePicked}
      />
    </form>
  );
}
