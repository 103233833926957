import { Children, cloneElement, ComponentPropsWithoutRef, ReactElement } from 'react';

import './Button.light.scss';

interface Props extends ComponentPropsWithoutRef<'button'> {
  variant: 'raised' | 'stroked' | 'basic';
  color: 'primary' | 'accent' | 'warning';
}

export function Button({ variant, color, children, className, disabled, onClick, ...rest }: Props) {
  const updatedChildren = Children.map(children, (child: ReactElement) => {
    const labelClassName = `button__label${child.props?.className ? ' ' + child.props.className : ''}`;

    return typeof child === 'string' ? (
      <span className={labelClassName}>{child}</span>
    ) : (
      cloneElement(child as any, { className: labelClassName })
    );
  });

  return (
    <button
      type='button'
      className={`button ${className ? className + ' ' : ''}variant--${variant} color--${color}`}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {updatedChildren}
    </button>
  );
}
