import { fromEvent, of, combineLatest, Subject, Observable, BehaviorSubject } from 'rxjs';
import { switchMap, map, takeUntil, take, filter, finalize } from 'rxjs/operators';

import { FileUpload, FileUploadStatus } from './FileUpload';

export class FilePickerService {
  private static readonly _INSTANCE_ = new FilePickerService();

  private readonly _fileSelectionCancelEvent = new Subject<void>();

  private _fileSelection = new BehaviorSubject<FileList>(null);

  static getInstance() {
    return FilePickerService._INSTANCE_;
  }

  private constructor() {
    window.onfocus = () => {
      setTimeout(() => {
        const filePicker = document.querySelector('.file-picker__input') as HTMLInputElement;
        if (filePicker.files.length === 0) {
          this._fileSelectionCancelEvent.next();
        }
      }, 250);
    };
  }

  selectFiles(fileList: FileList) {
    this._fileSelection.next(fileList);
  }

  clearSelection() {
    (document.querySelector('.file-picker') as HTMLFormElement).reset();
  }

  open(): FilePickerService {
    const filePicker = document.querySelector('.file-picker__input') as HTMLInputElement;
    filePicker.click();
    return this;
  }

  readFilesAsJson<T>(): Observable<FileUpload<T>[]> {
    return this._fileSelection.asObservable().pipe(
      filter(fileList => fileList !== null),
      map(fileList => Array.from(fileList).map(this._readSingleFileAsJson) as Observable<FileUpload<T>>[]),
      switchMap(fileObservables => combineLatest(fileObservables)),
      finalize(this.clearSelection),
      take(1),
      takeUntil(this._fileSelectionCancelEvent)
    );
  }

  private _readSingleFileAsJson(file: File) {
    const fileReader = new FileReader();

    const fileReaderResultObservable = fromEvent(fileReader, 'load').pipe(
      switchMap(() => {
        const fileUpload: FileUpload<unknown> = {
          file,
          content: null,
          errorMessage: '',
          status: FileUploadStatus.SUCCESS
        };
        if (file.type === 'application/json') {
          try {
            fileUpload.content = JSON.parse(fileReader.result as string);
          } catch (error) {
            fileUpload.errorMessage = (error as Error).message;
            fileUpload.status = FileUploadStatus.FAILURE;
          }
        } else {
          // prettier-ignore
          fileUpload.errorMessage = 'File\'s content does not have a valid JSON format';
          fileUpload.status = FileUploadStatus.FAILURE;
        }

        return of(fileUpload);
      })
    );

    fileReader.readAsText(file);

    return fileReaderResultObservable;
  }
}
