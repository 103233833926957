import { Component, ComponentPropsWithoutRef, createRef, ReactElement, ReactNode } from 'react';
import { Subject } from 'rxjs';

import { PortalRenderer } from '@common/overlay/portal-renderer';
import { Backdrop } from '@common/overlay/backdrop';

import { DialogContent } from './DialogContent';
import { DialogActionGroup } from './DialogActionGroup';

import './Dialog.light.scss';

interface Props extends ComponentPropsWithoutRef<any> {
  open: boolean;
  top?: number;
  left?: number;
  className?: string;
  transparentBackdrop?: boolean;
  onAfterClosed?: () => void;
  onBackdropClicked?: () => void;
}

interface State {
  open: boolean;
}

/**
 * You typically don't want to use this component directly, instead, you `Dialog.create()`
 * to create a dialog builder for configuring the dialog, see {@see Confirmation} class for
 * more detail on how to use the dialog builder
 */
export class Dialog extends Component<Props, State> {
  readonly dialogRef = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      open: props.open
    };

    this.onAnimationEnd = this.onAnimationEnd.bind(this);
  }

  /**
   * Create a new dialog builder with the given component as its content
   *
   * @param content The component to show in the dialog
   * @returns
   */
  static create(content: React.ReactNode) {
    return new DialogBuilder(content);
  }

  componentDidMount() {
    if (this.state.open) {
      this.dialogRef.current.parentElement.classList.add('active');
      this._shiftIntoViewIfOverflowScreen();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.open !== prevProps.open) {
      this.setState({
        open: this.props.open
      });
      if (this.props.open) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (this.props.children !== (prevProps as any).children) {
          this.dialogRef.current.style.top = `${this.props.top}px`;
        }
        this.dialogRef.current.parentElement.classList.add('active');
        this._shiftIntoViewIfOverflowScreen();
      }
    }
  }

  private _shiftIntoViewIfOverflowScreen() {
    const dialogElement = this.dialogRef.current;

    const popupElementBottomEdge = dialogElement.clientHeight + dialogElement.offsetTop;
    if (popupElementBottomEdge > window.innerHeight) {
      const overflowingHeight = popupElementBottomEdge - window.innerHeight;
      dialogElement.style.top = `${Math.max(dialogElement.offsetTop - overflowingHeight, 0)}px`;
    }
    const overflowingWidth = this.props.left + dialogElement.clientWidth - window.innerWidth;
    if (overflowingWidth > 0) {
      dialogElement.style.left = `${this.props.left - overflowingWidth - 5}px`;
    }
  }

  render() {
    return (
      <PortalRenderer
        containerClassName={`dialog-container${
          this.props.top === undefined || this.props.left === undefined ? ' centered' : ''
        }`}>
        <Backdrop
          visible={this.state.open}
          transparent={this.props.transparentBackdrop}
          onClick={this.props.onBackdropClicked}
        />
        <div
          ref={this.dialogRef}
          className={`dialog ${this.state.open ? 'dialog--entering' : 'dialog--leaving'}${
            this.props.className ? ' ' + this.props.className : ''
          }`}
          style={{
            left: this.props.left === 0 ? undefined : this.props.left,
            top: this.props.top === 0 ? undefined : this.props.top
          }}
          onAnimationEnd={this.onAnimationEnd}>
          {this.props.children}
        </div>
      </PortalRenderer>
    );
  }

  onAnimationEnd() {
    if (!this.state.open) {
      this.props.onAfterClosed?.();
      if (this.dialogRef.current) {
        this.dialogRef.current.parentElement.classList.remove('active');
      }
    }
  }

  close() {
    this.setState({
      open: false
    });
  }
}

export class DialogBuilder {
  private readonly _classNames: string[] = [];
  private readonly _buttons: JSX.Element[] = [];

  private _isDismissible = false;
  private _transparentBackdrop = false;

  constructor(private readonly _content: ReactNode) {}

  /**
   * Add another button to the dialog
   *
   * @param button The button to add
   * @returns This dialog builder instance
   */
  addButton(button: ReactElement) {
    this._buttons.push(button);
    return this;
  }

  /**
   * Add another class name for this dialog
   *
   * @param value The new class name
   * @returns This dialog builder instance
   */
  addClassName(value: string) {
    this._classNames.push(value);
    return this;
  }

  /**
   * Set whether this dialog can be closed by clicking on the backdrop, false by default
   *
   * @returns This dialog builder instance
   */
  dismissible() {
    this._isDismissible = true;
    return this;
  }

  /**
   * Set whether the backdrop should be transparent
   *
   * @returns This dialog builder instance
   */
  transparentBackdrop() {
    this._transparentBackdrop = true;
    return this;
  }

  /**
   * Open the dialog, optionally anchored at position specified by `left` and `top`.
   * If the position is not specified, the dialog will be centered on the screen
   *
   * @param left The horizontal position
   * @param top The vertical position
   * @returns
   */
  open(left?: number, top?: number) {
    const dialogRef = createRef<Dialog>();
    const portalRenderer = new PortalRenderer();
    const afterClosed = new Subject<void>();

    portalRenderer.mount(
      <Dialog
        open
        ref={dialogRef}
        className={this._classNames.join(' ')}
        left={left}
        top={top}
        transparentBackdrop={this._transparentBackdrop}
        onAfterClosed={() => {
          portalRenderer.unmount();
          afterClosed.next();
          afterClosed.complete();
        }}
        onBackdropClicked={() => {
          if (this._isDismissible) {
            dialogRef.current.close();
          }
        }}>
        <DialogContent>{this._content}</DialogContent>
        <DialogActionGroup>
          {this._buttons.map((Button, i) => (
            <Button.type
              key={i}
              {...Button.props}
            />
          ))}
        </DialogActionGroup>
      </Dialog>
    );

    return new (class DialogRef {
      /**
       * Manually close this dialog
       */
      close() {
        dialogRef.current.close();
      }

      /**
       * On observable that emits when this dialog is closed
       *
       * @returns
       */
      afterClosed() {
        return afterClosed.asObservable();
      }
    })();
  }
}
