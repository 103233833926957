import { ReactNode, useContext, useEffect, useState } from 'react';

import { Ripple } from '@common/effects/ripple';
import { Icon } from '@common/icon';
import { generateUniqueId } from '@common/misc';

import { RADIO_BUTTON_CONTEXT } from './RadioButtonGroup';

import './RadioButton.light.scss';

interface Props<T> {
  label: ReactNode;
  value: T;
  children?: ReactNode;
}

export function RadioButton<T>(props: Props<T>) {
  const { groupId, formControl } = useContext(RADIO_BUTTON_CONTEXT);
  const selected = formControl.getValue() === props.value;
  const [isSelected, setIsSelected] = useState(selected);
  const [id] = useState(generateUniqueId());
  const onChange = () => {
    formControl.setValue(props.value);
    setIsSelected(true);
  };

  useEffect(() => {
    const sub = formControl.valueChanges().subscribe({
      next: newValue => {
        setIsSelected(newValue === props.value);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [formControl, props.value]);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  return (
    <div className='radio-button'>
      <div className='radio-button__circle'>
        <Ripple
          fixed
          duration={1500}
        />
        <input
          type='radio'
          className='radio-button__input'
          name={groupId}
          id={id}
          checked={isSelected}
          onChange={onChange}
        />
        <Icon
          className='radio-button__icon radio-button__unchecked-icon'
          icon='radio_button_unchecked'
        />
        <Icon
          className='radio-button__icon radio-button__checked-icon'
          icon='radio_button_checked'
        />
      </div>
      <div className='radio-button__label-container'>
        <label
          className='radio-button__label'
          htmlFor={id}
          onClick={onChange}>
          <Ripple
            fixed
            duration={1500}
          />
          {props.label}
        </label>
        {props.children}
      </div>
    </div>
  );
}
