import { createContext, useState } from 'react';

import { generateUniqueId } from '@common/misc';

import { FormField } from '../form-field';
import { FormControl } from '../models';

import './RadioButtonGroup.light.scss';

export const RADIO_BUTTON_CONTEXT = createContext({
  groupId: '',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formControl: null as FormControl<any>
});

interface Props<T> {
  label: string;
  formControl: FormControl<T>;
  children: React.ReactNode;
}

export function RadioButtonGroup<T>(props: Props<T>) {
  const [groupId] = useState(generateUniqueId());

  return (
    <FormField
      className='radio-button-group'
      formControl={props.formControl}
      label={props.label}>
      <RADIO_BUTTON_CONTEXT.Provider
        value={{
          groupId,
          formControl: props.formControl
        }}>
        {props.children}
      </RADIO_BUTTON_CONTEXT.Provider>
    </FormField>
  );
}
